import React from "react"
import SectionDark from "./sections/section-dark"
import { StaticImage } from "gatsby-plugin-image"

export default function Contact() {
  return (
    <>
      <span id="contact" />
      <SectionDark>
        <h2>Contact Us</h2>
        <hr />
        <div
          className="uk-grid uk-child-width-1-2@m uk-grid-divider"
          uk-grid="true"
        >
          <div>
            <h4>SCAN PIs</h4>
            <p className="uk-margin-left">
              <span className="uk-text-bold">Bill Jagust</span>
              <span className="uk-margin-left">
                <a
                  className="uk-button-text uk-button uk-text-bold"
                  href="mailto:jagust@berkeley.edu"
                >
                  jagust@berkeley.edu
                </a>
              </span>
            </p>
            <p className="uk-margin-left">
              <span className="uk-text-bold">Cliff Jack</span>
              <span className="uk-margin-left">
                <a
                  className="uk-button-text uk-button uk-text-bold"
                  href="mailto:jack.clifford@mayo.edu"
                >
                  jack.clifford@mayo.edu
                </a>
              </span>
            </p>
            <h4>SCAN PET Team</h4>
            <p className="uk-margin-left">
              <span className="uk-text-bold">Susan Landau</span>
              <span className="uk-margin-left">
                <a
                  className="uk-button-text uk-button uk-text-bold"
                  href="mailto:slandau@berkeley.edu"
                >
                  slandau@berkeley.edu
                </a>
              </span>
            </p>
            <p className="uk-margin-left">
              <span className="uk-text-bold">Tessa Harrison</span>
              <span className="uk-margin-left">
                <a
                  className="uk-button-text uk-button uk-text-bold"
                  href="mailto:tessaharrison@berkeley.edu"
                >
                  tessaharrison@berkeley.edu
                </a>
              </span>
            </p>
            <p>
              <span className="uk-h4">SCAN MRI Team</span>
              <span className="uk-margin-left">
                <a
                  className="uk-button-text uk-button uk-text-bold"
                  href="mailto:SCANMRI@mayo.edu"
                >
                  SCANMRI@mayo.edu
                </a>
              </span>
            </p>
            <p>
              <span className="uk-h4">
                <a
                  href="https://naccdata.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="National Alzheimer's Coordination Center"
                  className="uk-button-text"
                >
                  NACC
                </a>
              </span>
              <span className="uk-margin-left">
                <a
                  className="uk-button-text uk-button uk-text-bold"
                  href="mailto:nacchelp@uw.edu"
                >
                  nacchelp@uw.edu
                </a>
              </span>
            </p>
            <p>
              <span className="uk-h4">
                <a
                  href="https://www.loni.usc.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="National Alzheimer's Coordination Center"
                  className="uk-button-text"
                >
                  LONI
                </a>
              </span>
              <span className="uk-margin-left">
                <a
                  className="uk-button-text uk-button uk-text-bold"
                  href="mailto:data.coordinator@loni.usc.edu"
                >
                  data.coordinator@loni.usc.edu
                </a>
              </span>
            </p>
          </div>

          <div>
            <a
              href="https://discourse.naccdata.org/"
              target="_blank"
              rel="noopener noreferrer"
              class="uk-inline"
            >
              <StaticImage
                src={"../assets/images/forum.png"}
                alt="forum image"
                width={400}
                height={220}
              />
              <div class="uk-overlay uk-overlay-primary uk-position-cover">
                <p className="uk-text-primary">
                  Visit the ADRC Program Community Forum for additional support
                  for the SCAN Initiative
                </p>
              </div>
            </a>
            <h4>Office Hours</h4>
            <p className="uk-margin-left">
              The first Tuesday of every month we will hold{" "}
              <a href="https://urldefense.com/v3/__https://berkeley.zoom.us/j/95689506565__;!!K-Hz7m0Vt54!jAU5B3pBA-wrAo3-lgO1VAgXbGB8bZKjMNFCODrnDQm-yARfS5fnsexY6dQ9MiLZ3g0bUEP1iY5c8BT-f44$">
                open office hours via Zoom
              </a>
              . SCAN investigators are standing by and prepared to answer your
              questions about imaging protocols, uploading, private imaging
              dashboards or any SCAN (including CLARiTI images, and P30-funded
              images) related issues you have.
            </p>
          </div>
        </div>
      </SectionDark>
    </>
  )
}
