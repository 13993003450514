import React from "react"
import Dashboard from "./scan-dashboard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faFileAlt } from "@fortawesome/free-solid-svg-icons"
import SectionDefault from "./sections/section-default"

export default function DashboardSection() {
  return (
    <>
      <span id="dashboard" />
      <SectionDefault>
        <h2 className="uk-heading-divider">SCAN Dashboard</h2>
        <div
          className="uk-margin-top uk-grid-small uk-child-width-1-2@m"
          data-uk-grid="true"
        >
          <div>
            <p>
              This dashboard tracks the number of Standardized Centralized
              Alzheimer’s and Related Dementias Neuroimaging (SCAN)-compliant
              MR/PET images that have passed QC and are available for analysis.
              SCAN MRI and PET data that has passed QC and been analyzed is
              available to all researchers, worldwide, via NACC’s Data Front
              Door –{" "}
              <a
                href="https://naccdata.org/requesting-data/submit-data-request"
                className="uk-button-link "
              >
                Learn more here
              </a>
              .
            </p>
            <p>
              All data was submitted by each Alzheimer’s Disease Research Center
              (ADRC) to the Laboratory of Neuro Imaging (LONI) for the SCAN
              Initiative.
            </p>
          </div>{" "}
          <div className="uk-margin-auto uk-margin-bottom">
            <div className="uk-grid uk-child-width-1-1@s uk-padding-small">
              <p className="bolder uk-text-center uk-text-large">
                New to the SCAN Dashboards?
              </p>
              <div className="uk-text-center">
                <p className="bolder">Check out the </p>
                <a
                  href="https://files.alz.washington.edu/scan/scan_dashboard_quick_guide.pdf"
                  title="SCAN Quick Guide"
                  className="uk-button uk-button-primary bolder"
                >
                  SCAN Dashboard Quick Guide
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    size={"1x"}
                    className="uk-margin-small-left"
                  />
                </a>
                <p className="bolder uk-margin-small">or</p>
                <a
                  href="#faq"
                  data-uk-scroll="offset: 20"
                  title="Learn more"
                  className="uk-button uk-button-primary  bolder"
                >
                  Learn more about the SCAN Dashboards
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    size={"1x"}
                    className="uk-margin-small-left"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-padding-large uk-width-2-3@l uk-margin-auto">
          <iframe
            src="https://www.youtube-nocookie.com/embed/uFxI3gs9yKU?autoplay=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=1&amp;playsinline=0"
            width="1422"
            height="800"
            allowfullscreen
            data-uk-responsive
            data-uk-video="false"
            title="SCAN Dashboard Tutorial"
          ></iframe>
        </div>
        <div className="uk-label-warning uk-padding-small uk-text-center uk-text-primary">
          <p className="uk-text-primary uk-text-lead">
            Important Update: This dashboard has been updated to reflect the
            total number of SCAN MRI and PET data that have passed QC and are
            available for analysis.
          </p>
          <p>
            The totals reflected in the SCAN Public Dashboard may differ from
            the total number of images your ADRC has submitted as it takes time
            for the SCAN team to evaluate images for compliance and to perform
            de-facing, quality control, and analysis prior to releasing this
            data to researchers. For a more complete picture of your total
            submissions by center and where they are at in the QC pipeline,
            please visit your ADRC-specific SCAN QC Dashboard via the “private
            dash” links below.
          </p>
        </div>
        <Dashboard />
        <p className="uk-text-meta">
          Please note: Only ADRC members with data access privileges can view
          the{" "}
          <a href="https://flywheel.naccdata.org/#/projects/65e1164c0fc1dd593aee4206/information?viewerParams=app%3D66b17117f74b084695ae6b4d;container%3D65e1164c0fc1dd593aee4206;">
            ADRC-Specific SCAN QC Dashboards
          </a>
          . Learn how to get access in the{" "}
          <a
            href="https://files.alz.washington.edu/scan/scan_dashboard_quick_guide.pdf"
            className="uk-button-text"
          >
            SCAN Dashboard Quick Guide
          </a>
          .
        </p>
      </SectionDefault>
    </>
  )
}
